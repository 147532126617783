import { ArrowDropUp } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import {
  BackToTopContainer,
  BackToTopText,
} from './styles';

export interface BackToTopButtonProps {
  onClick: () => void,
}

function BackToTopButton({ onClick }: BackToTopButtonProps) {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setShow(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <BackToTopContainer
      onClick={onClick}
      whileTap={{ scale: 0.9, opacity: 0.9 }}
    >
      <BackToTopText>
        <ArrowDropUp />
      </BackToTopText>
    </BackToTopContainer>
  );
}

export default BackToTopButton;
