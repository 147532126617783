import { Grid } from '@mui/material';
import bdcLogo from 'assets/images/home/logoPartenaires/logo-bdc.png';
import mtlabLogo from 'assets/images/home/logoPartenaires/logo-MTLAB.png';
import cnrcLogo from 'assets/images/home/logoPartenaires/logo-paricnrc.png';
import pcanLogo from 'assets/images/home/logoPartenaires/logo-PCAN.png';
import MovonLogo from 'assets/images/logo/movon-couleur-horizontal-moyen.png';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  BottomFooter,
  CenteredBottomGrid,
  CenteredItem,
  FooterContainer,
  FormContainer,
  FormSectionContainer,
  JoinUsSubtitle,
  JoinUsText,
  LogoContainer,
  PartnerContainer,
  PartnerLogo,
  PartnersContainer,
  PartnersTitle,
  PCANLogo,
  RightsReserved,
  SocialMediaContainer,
  SpacedGrid,
  StyledTextField,
  SubmitButton,
  TextFieldName,
  TopFooter,
} from './styles';

function HomeFooter() {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const d = new Date();
  const year = d.getFullYear();

  // Form non visible pour le moment, sera utilisé une fois que la fonctionnalité d'envoie
  // d'email sera terminée
  // TODO: Add form submit functionality
  // https://app.shortcut.com/novom/story/6524/form-submit-dans-nous-joindre-sur-la-home-page
  const onFormSubmit = () => {
    console.log('Form submitted');
  };

  return (
    <FooterContainer>
      <TopFooter>
        <PartnersTitle>
          <FormattedMessage id="home_footer_partners" />
        </PartnersTitle>
        <PartnersContainer>
          <Grid container>
            <PartnerContainer item lg={3} xs={12}>
              <PartnerLogo src={cnrcLogo} />
            </PartnerContainer>
            <PartnerContainer item lg={3} xs={12}>
              <PartnerLogo src={mtlabLogo} />
            </PartnerContainer>
            <PartnerContainer item lg={3} xs={12}>
              <PCANLogo src={pcanLogo} />
            </PartnerContainer>
            <PartnerContainer item lg={3} xs={12}>
              <PartnerLogo src={bdcLogo} />
            </PartnerContainer>
          </Grid>
        </PartnersContainer>
      </TopFooter>
      <FormSectionContainer>
        <JoinUsText>
          <FormattedMessage id="home_footer_touch" />
        </JoinUsText>
        <JoinUsSubtitle>
          lorem ipsum somefin qwer qwer qwer qwer qwer
        </JoinUsSubtitle>
        <FormContainer>
          <Grid container>
            <SpacedGrid item lg={12} xs={12}>
              <TextFieldName>
                <FormattedMessage id="home_footer_name" />
              </TextFieldName>
              <StyledTextField
                variant="outlined"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </SpacedGrid>
            <SpacedGrid item lg={12} xs={12}>
              <TextFieldName>
                <FormattedMessage id="home_footer_email" />
              </TextFieldName>
              <StyledTextField
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </SpacedGrid>
            <SpacedGrid item lg={12} xs={12}>
              <TextFieldName>
                <FormattedMessage id="home_footer_message" />
              </TextFieldName>
              <StyledTextField
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                multiline
                rows={4}
              />
            </SpacedGrid>
            <CenteredItem>
              <SubmitButton
                onClick={onFormSubmit}
                whileTap={{ scale: 0.9, opacity: 0.9 }}
              >
                <FormattedMessage id="home_footer_submit" />
              </SubmitButton>
            </CenteredItem>
          </Grid>
        </FormContainer>
      </FormSectionContainer>
      <BottomFooter>
        <Grid container>
          <CenteredBottomGrid item lg={4} xs={12}>
            <LogoContainer src={MovonLogo} />
          </CenteredBottomGrid>
          <CenteredBottomGrid item lg={4} xs={12}>
            <RightsReserved>
              <FormattedMessage id="home_footer_rights" values={{ year }} />
            </RightsReserved>
          </CenteredBottomGrid>
          <CenteredBottomGrid item lg={4} xs={12}>
            <SocialMediaContainer />
          </CenteredBottomGrid>
        </Grid>
      </BottomFooter>
    </FooterContainer>
  );
}

export default HomeFooter;
