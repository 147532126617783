const messages: I18NMessages = {
  about_novom: 'Acerca de Novom',

  cart_choose_for_me: 'Elige por mí',
  cart_choose: 'Quiero elegir',
  cart_summary: 'Resumen',
  cart_impact: 'Impacto',
  cart_impact_description: 'Por cada billete MovOn VIP vendida',
  cart_impact_follow: '33% será donado a una causa benéfica',
  cart_payment: 'Pago',
  cart_phone: 'Número de teléfono',
  cart_email: 'Correo electrónico',
  cart_info_missing: 'Falta información',
  cart_phone_invalid: 'Número de teléfono inválido',
  cart_email_invalid: 'Correo electrónico inválido',
  cart_email_instead: 'Correo electrónico',
  cart_phone_instead: 'Número de teléfono',
  cart_more_options: 'Más opciones',
  cart_nickname: 'Nombre o apodo (opcional)',
  cart_pay_google: 'Pagar con G Pay',
  cart_pay_credit: 'Pagar con tarjeta de crédito',
  cart_pay_total: 'Total',
  cart_remove: 'Eliminar',
  cart_subtotal: 'Total parcial',
  cart_tax: 'Impuestos',

  general_back: 'Volver',
  general_next: 'Próximo',
  general_close: 'Cerrar',
  general_select: 'Elegir',

  home_access_title_part_1: '¿Qué es el acceso MovOn',
  home_access_title_part_2: '?',
  home_access_description: 'MovOn promueve la idea de un avance continuo. Este concepto va '
  + 'más allá de una simple invitación al avance individual, proponemos más bien una progresión constante y colectiva.',
  home_access_left_card_part_1: 'Para los ',
  home_access_left_card_part_2: ' eventos culturales, deportivos o atracciones turísticas, MovOn permite a ir más allá '
  + 'de la experiencia y canaliza la idea de que cada momento es una oportunidad de crecimiento y mejora de nuestro bienestar colectivo.',
  home_access_left_card_visitors: 'visitantes',
  home_access_right_card_part_1: 'Para ti, ',
  home_access_right_card_part_2: ', MovOn es un llamado a la innovación, la adaptabilidad y la búsqueda constante de medios para mejorar '
  + 'y transformar las experiencias que puedes ofrecer a tus clientes.',
  home_access_right_card_organizers: 'organizador',

  home_faq_title: 'Preguntas frecuentes',
  home_faq_description: '¡Bienvenido a nuestra sección de preguntas frecuentes! Aquí encontrarás respuestas a las '
  + 'preguntas frecuentes sobre nuestros productos, servicios y políticas. Si tienes más preguntas, no dudes '
  + 'en contactarnos. Estamos aquí para simplificar tu experiencia.',
  home_faq_subtitle_1: '¿MovOn.VIP es para ti?',
  home_faq_answer_1: 'Eres gerente de atracción u organizador de eventos, ¿y puede haber tiempo de espera en la entrada? '
  + 'MovOn.VIP es ideal para ti. Como ejemplos, podrías estar en uno de los siguientes contextos: grandes eventos, '
  + 'conciertos y festivales, sitios deportivos, museos y exposiciones, atracciones urbanas para el público en general, '
  + 'salas de espectáculos (admisión general), parques de atracciones, ferias populares, cruceros, excursiones, parques '
  + 'naturales, zoológicos o cualquier otro evento especial.',
  home_faq_subtitle_2: '¿Qué significa "MovOn"?',
  home_faq_answer_2: 'MovOn significa "Move on" en inglés. Esto encarna la idea de un movimiento perpetuo hacia adelante. Este concepto '
  + 'va más allá de una simple invitación al desplazamiento físico, abarcando más bien una progresión constante y colectiva.',
  home_faq_subtitle_3: '¿Qué significa "V.I.P."?',
  home_faq_answer_3: 'Para nosotros, VIP significa "Very Important Planet!". Esto va más allá de la noción tradicional '
  + 'de estatus privilegiado. Al adoptar este acrónimo, se pone énfasis en la importancia crucial de nuestro planeta '
  + 'y en la responsabilidad de cada uno de nosotros hacia la humanidad.',
  home_faq_subtitle_4: '¿Cuáles son los 17 objetivos de desarrollo sostenible de las naciones unidas?',
  home_faq_answer_4: 'Los 17 Objetivos de Desarrollo Sostenible (ODS) de las Naciones Unidas son un conjunto de '
  + 'objetivos globales establecidos para abordar grandes desafíos como la pobreza, la desigualdad, el cambio '
  + 'climático, la paz y la justicia. Estos objetivos fueron adoptados en septiembre de 2015 como parte de la '
  + 'Agenda 2030 para el Desarrollo Sostenible. Aquí está la lista de los 17 ODS:',
  home_faq_subtitle_5: '¿Cuánto cuesta para ti, como organizadores?',
  home_faq_answer_5_part_1: 'No sólo MovOn.VIP no te cuesta nada, ¡sino que es una fuente adicional de ingresos para ti! De hecho, recibes el',
  home_faq_answer_5_part_2: 'de todos los fondos MovOn.VIP recaudados durante tu evento/atracción!',
  home_faq_subtitle_6: '¿En qué idiomas está disponible MovOn.VIP?',
  home_faq_answer_6: 'El servicio MovOn.VIP es internacional. Actualmente está disponible en español, inglés y francés. '
  + 'Pronto se ofrecerán otros idiomas.',
  home_faq_subtitle_7: '¿Como visitante y usuario, debo instalar una aplicación móvil?',
  home_faq_answer_7: 'No. No hay ninguna aplicación móvil que instalar. Todo se realiza en línea, '
  + 'ingresando a www.MovOn.VIP/access desde tu teléfono móvil o tableta.',
  home_faq_subtitle_8: '¿Como organizador o gestor, debo instalar algún software?',
  home_faq_answer_8: 'No. No hay ningún software que instalar: todo es seguro y en línea. Ya sea para '
  + 'crear tu cuenta organizativa, ingresar la información de tu evento/atracción, gestionar y '
  + 'controlar los accesos con código QR, gestionar informes detallados de eventos y finalmente '
  + 'recibir tu parte de los fondos recaudados.',
  home_faq_subtitle_9: '¿Quién es Novom Interactive?',
  home_faq_answer_9: 'Novom Interactive es una empresa canadiense, una "spin-off" universitaria fundada en 2012, que '
  + 'reúne a un equipo de investigadores universitarios, creadores artísticos, expertos en atracciones y eventos, '
  + 'así como desarrolladores de software. Nuestro equipo es el creador de del producto MovOn.VIP, una '
  + 'solución simple y efectiva, a la vez vanguardista y original. Esta iniciativa surge de una voluntad compartida '
  + 'dentro del grupo, acompañada de une compromiso firme para mejorar nuestro planeta y fomentar el bienestar '
  + 'de las personas que lo habitan. Con MovOn.VIP, aspiramos a crear una plataforma, propicia para '
  + 'el avance colectivo y la realización de objetivos sostenibles, con miras a un futuro mejor para todos.',
  home_faq_subtitle_10: 'Como organizador, ¿qué porcentaje de los ingresos tengo para contribuir a las OSFL-ODS?',
  home_faq_answer_10_part_1: 'De todos los fondos recaudados por el acceso MovOn.VIP, el',
  home_faq_answer_10_part_2: 'debe ser aportado a organizaciones adheridas a los Objetivos de Desarrollo Sostenible '
  + '(ODS) de las Naciones Unidas.',
  home_faq_goal_1: 'Fin de la pobreza',
  home_faq_goal_2: 'Hambre Cero',
  home_faq_goal_3: 'Salud y Bienestar',
  home_faq_goal_4: 'Educación de calidad',
  home_faq_goal_5: 'Igualdad de Género',
  home_faq_goal_6: 'Agua limpia y saneamiento',
  home_faq_goal_7: 'Energía asequible y no contaminante',
  home_faq_goal_8: 'Trabajo decente y crecimiento económico',
  home_faq_goal_9: 'Industria, Innovación e Infraestructura',
  home_faq_goal_10: 'Reducción de las desigualdades',
  home_faq_goal_11: 'Ciudades y comunidades sostenibles',
  home_faq_goal_12: 'Producción y consumo responsables',
  home_faq_goal_13: 'Acción climática',
  home_faq_goal_14: 'Vida bajo el agua',
  home_faq_goal_15: 'Vida en la Tierra',
  home_faq_goal_16: 'Instituciones sólidas para la paz y la justicia',
  home_faq_goal_17: 'Alianzas para lograr los objetivos',

  home_footer_rights: '©Movon.VIP {year} - Orgullosa creación canadiense',
  home_footer_submit: 'Enviar',
  home_footer_partners: 'Nuestros compañeros',
  home_footer_touch: 'Ponerse en contacto',
  home_footer_name: 'Nombre',
  home_footer_email: 'Correo electrónico',
  home_footer_message: 'Mensaje',

  home_intro_title: 'Ahorra tiempo valioso en las colas',
  home_intro_subtitle: 'Lanzamiento oficial en verano de 2024',
  home_intro_button: 'Más información',
  home_intro_organizer: '¿Eres organizador de eventos?',

  home_nav_about: 'Acerca de',
  home_nav_faq: 'FAQ',
  home_nav_organizers: 'Organizadores',
  home_nav_use_cases: 'Casos prácticos',
  home_nav_visitors: 'Visitantes',

  home_organizer_question_part_1: '¿Eres ',
  home_organizer_question_part_2: ' de eventos o gestor de atracciones TURÍSTICAS?',
  home_organizer_question_organizer: 'organizador',
  home_organizer_title_part_1: '¡Aumenta tus ingresos y mejora la ',
  home_organizer_title_part_2: ' del cliente!',
  home_organizer_title_experience: 'experiencia',
  home_organizer_benefits: 'Ventajas:',
  home_organizer_benefit_1: 'Fuente de ingresos adicional para ti (¡sí!)',
  home_organizer_benefit_2: 'Tratamiento VIP para tus visitantes que lo deseen, desde su llegada',
  home_organizer_benefit_3: 'Contribución automática a los objetivos de desarrollo sostenible',
  home_organizer_benefit_4: 'Gran facilidad y simplicidad en la implementación de nuestro sistema',
  home_organizer_benefit_5: 'Visitantes más satisfechos que se suman a tu visión humanitaria',

  home_passport_title: 'Pasaporte VIP Silver u Gold:',
  home_passport_subtitle: '¿Cuál es el adecuado para ti?',
  home_passport_silver_title: 'Acceso acelerado.',
  home_passport_gold_title: 'Acceso inmediato.',
  home_passport_silver_description: ' Con VIP Silver, esperas donde quieras, no en una larga '
  + 'cola, antes de acceder al evento o la atracción. Así que aprovecha ese tiempo para hacer algo '
  + 'más útil que esperar en una fila. ¿Interesante, verdad?',
  home_passport_gold_description: 'Acceso inmediato. Con VIP Gold, aún mejor, ¡no tienes que '
  + 'esperar en absoluto! Tan pronto como el organizador del evento lo permita, tu acceso se activa '
  + 'y puedes entrar en cuanto llegues, sin importar el momento. ¿Genial, no?',

  home_usecases_title: '¿Cuáles son los lugares que pueden beneficiarse del acceso privilegiado de MovOn.VIP?',
  home_usecases_sports: 'Sitios deportivos',
  home_usecases_events: 'Grandes eventos',
  home_usecases_concerts: 'Conciertos y festivales',
  home_usecases_museums: 'Museos y Exposiciones',
  home_usecases_attractions: 'Atracciones urbanas para el público en general',
  home_usecases_venues: 'Salas de espectáculos (admisión generales)',
  home_usecases_parks: 'Parques de atracciones y ferias populares',
  home_usecases_cruises: 'Cruceros y excursiones',
  home_usecases_zoos: 'Parques naturales y zoológicos',

  home_vip_title: 'VIP (¡Very Important Planet!)',
  home_vip_description: 'VIP va más allá de la noción tradicional de estatus privilegiado. '
  + 'Al adoptar este acrónimo, se pone énfasis en la importancia crucial de nuestro planeta '
  + 'y en la responsabilidad de cada uno hacia toda la humanidad.',
  home_vip_description_2_part_1: 'Ser "',
  home_vip_description_2_part_2: '" implica destacar las contribuciones y acciones necesarias para '
  + 'mejorar nuestro planeta, alineándose con los 17 objetivos de desarrollo sostenible de las Naciones '
  + 'Unidas. Estos objetivos, como la lucha contra el cambio climático, el acceso a una educación de calidad, '
  + 'la promoción de la igualdad de género y la erradicación de la pobreza, se convierten en los pilares de la '
  + 'visión "Very Important Planet".',
  home_vip_goals: 'Al adherir a estos objetivos, cada individuo se convierte en un actor crucial en la '
  + 'preservación de nuestro planeta y en la construcción de un futuro más sostenible y equitable para todos.',

  home_visitor_subtitle_part_1: 'eres ',
  home_visitor_subtitle_part_2: ' de evento cultural o de una atracción turística:',
  home_visitor_subtitle_visitors: 'visitante',
  home_visitor_title_part_1: '¡Disfruta de un acceso más ',
  home_visitor_title_part_2: ' y ayuda a nuestro ',
  home_visitor_title_faster: 'rápido',
  home_visitor_title_planet: 'planeta',
  home_visitor_benefits: 'Ventajas:',
  home_visitor_benefit_1: 'Adiós a los tiempos de espera en el lugar, ¡entra de inmediato!',
  home_visitor_benefit_2: 'Disfruta de una bienvenida VIP para acceder a tu evento/atracción:',
  home_visitor_benefit_3: 'espera donde quieras; luego, en el momento adecuado, recibe una notificación para entrar.',
  home_visitor_benefit_4: 'tu acceso es inmediato y prioritario, sin importar cuándo llegues.',
  home_visitor_benefit_5: 'Tu micropago VIP también contribuye a ayudar al planeta.',
  home_visitor_benefit_6: 'Vive una experiencia maravillosa de principio a fin, sin inconvenientes.',
  home_visitor_vip_gold: 'VIP Gold: ',
  home_visitor_vip_silver: 'VIP Silver: ',

  home_work_title: '¿Cómo funciona nuestro sistema?',
  home_work_visitor: '¿Eres visitante?',
  home_work_organizer: '¿Eres organizador?',
  home_work_visitor_1: 'Es muy sencillo. Visita www.MovOn.VIP/access desde tu teléfono (sin necesidad de '
  + 'instalar ninguna aplicación), elige tu evento/atracción.',
  home_work_visitor_2: 'Con tu micropago, ingresa tu nombre y número de teléfono (no es necesario crear '
  + 'una cuenta de usuario). Recibirás una confirmación y un código QR.',
  home_work_visitor_3: 'Entonces estarás en la "fast track", esperando un mensaje de texto que te '
  + 'invitará a dirigirte a la entrada cuando se acerque tu turno.',
  home_work_visitor_4: 'Al llegar a la entrada, simplemente muestra tu código QR y disfruta de un acceso '
  + 'inmediato. ¿Simple, verdad?',
  home_work_organizer_1: 'Es sencillo. Visita www.MovOn.VIP/account desde una computadora o tableta, '
  + 'ingresa la información requerida para crear tu evento/atracción. Luego, accede a la sección '
  + 'www.MovOn.VIP/manager, donde podrás iniciar la gestión de accesos VIP para tus visitantes.',
  home_work_organizer_2: 'Luego, a medida que tus clientes se inscriban como visitantes VIP '
  + '(Silver o Gold), los verás aparecer en tu página de gestión de accesos.',
  home_work_organizer_3: 'También se proporcionará una página web adaptativa ("responsive") '
  + 'www.MovOn.VIP/QRcode para el dia del evento, que te permitirá escanear los códigos QR en los teléfonos '
  + 'de tus visitantes a medida que llegan a la entrada. Esto te permitirá validar su acceso VIP '
  + 'y permitirles la entrada. Toda la gestión de los flujos financieros se maneja automáticamente '
  + 'a través de la plataforma..',
  home_work_organizer_4: 'Y al final del evento, tendrás acceso a un reporte detallado en línea, '
  + 'así como al monto recaudado por transferencia bancaria. No hay software que instalar: todo '
  + 'es seguro y en línea. ¿No es maravilloso?',

  onboarding_cause: 'Apoye una causa que esté cerca de su corazón.',
  onboarding_obtain: 'Obtén acceso VIP',

  movon_title: 'MovOn.VIP',

  pass_congratulations: '¡Felicidades {name}!',
  pass_download_pass: 'o descarga tu pase',
  pass_owner_gold: 'Pase VIP Gold de {name}',
  pass_owner_silver: 'Pase VIP Silver de {name}',
  pass_owner_silver_no_name: 'Su Pass VIP Silver',
  pass_owner_gold_no_name: 'Su Pass VIP Gold',
  pass_gold_vip: 'Ahora eres VIP Gold',
  pass_silver_vip: 'Ahora eres VIP Silver',
  pass_help: '¿Necesitas ayuda?',
  pass_line_status: 'Estado de la línea de espera',
  pass_resend_confirmation: 'Reenviar la confirmación',
  pass_sent_to: 'Su pase ha sido enviado a {email}',
  pass_show_pass: 'Muestre su pase al cajero para saltarse la cola',
  pass_what_next: '¿Qué sigue?',
  pass_qr_wallet: 'Agrerar a Google Wallet',

  payment_information: 'Información de pago',

  terms_conditions: 'Términos y Condiciones',

  tickets_checkout: 'Pagar',
  tickets_gold_title: 'Saltarse la cola',
  tickets_silver_title: 'Guarda tu lugar',
  tickets_gold_description: 'El pase VIP Gold te da acceso inmediato a la atracción.',
  tickets_group_size: 'Max: ',
  tickets_group_size_2: '{limit} boleto{limit, plural, one {} other{s}}',
  tickets_available: '{quantity} disponible',
  tickets_quantity: 'Cantidad',
};

export default messages;
