import { useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import messages from '../../i18n';
import LanguageContext, { DefaultLanguageContext, Languages } from './LanguageContext';

export interface LanguageProviderProps {
  children: JSX.Element
}

function LanguageProvider(props: LanguageProviderProps) {
  const { children } = props;

  const [language, setLanguage] = useState<Languages>(DefaultLanguageContext.language);

  const contextValue = useMemo(() => ({
    language,
    setLanguage,
  }), [language]);

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

export default LanguageProvider;
