import {
  createTheme,
  PaletteOptions,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { useMemo } from 'react';

function AppThemeProvider({ children }: { children: JSX.Element }) {
  const theme : Theme = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const light: PaletteOptions = {
      mode: 'light',
      text: {
        primary: '#000000',
      },
    };

    const dark: PaletteOptions = {
      mode: 'dark',
      text: {
        primary: '#000000',
      },
      background: {
        default: '#808080',
      },
      primary: {
        main: '#000000',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000',
        contrastText: '#000000',
      },
    };
    const palette: PaletteOptions = dark;

    return createTheme({
      palette,
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default AppThemeProvider;
