import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Centered } from '../shared';
import {
  BottomContainer,
  CenteredContainer,
  Description,
  GoalsDescription,
  SectionContainer,
  SideDivContainer,
  Title,
  TitleContainer,
  VerticalContainer,
  VIPAccent,
  VipDescription,
} from './styles';

function VIPSection() {
  return (
    <SectionContainer>
      <CenteredContainer>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Centered>
              <TitleContainer>
                <Title>
                  <FormattedMessage id="home_vip_title" />
                </Title>
              </TitleContainer>
            </Centered>
          </Grid>
          <Centered>
            <BottomContainer>
              <Grid item lg={12} xs={12}>
                <Centered>
                  <Description>
                    <FormattedMessage id="home_vip_description" />
                  </Description>
                </Centered>
              </Grid>
              <SideDivContainer>
                <Grid item lg={12} xs={12}>
                  <VerticalContainer>
                    <VipDescription>
                      <FormattedMessage id="home_vip_description_2_part_1" />
                      <VIPAccent>VIP</VIPAccent>
                      <FormattedMessage id="home_vip_description_2_part_2" />
                    </VipDescription>
                    <GoalsDescription>
                      <FormattedMessage id="home_vip_goals" />
                    </GoalsDescription>
                  </VerticalContainer>
                </Grid>
              </SideDivContainer>
            </BottomContainer>
          </Centered>
        </Grid>
      </CenteredContainer>
    </SectionContainer>
  );
}

export default VIPSection;
