import { Grid } from '@mui/material';
import goldTicket from 'assets/images/home/gold-ticket-v2.png';
import silverTicket from 'assets/images/home/Silver-ticket-v2.png';
import { FormattedMessage } from 'react-intl';

import {
  CenteredContainer,
  ColumnContainer,
  Description,
  ImageContainer,
  PaddingCenter,
  SectionContainer,
  SidesContainer,
  Subtitle,
  Title,
  TitleBold,
  TitleContainer,
} from './styles';

function PassportSection() {
  return (
    <SectionContainer>
      <CenteredContainer>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <TitleContainer>
              <TitleBold>
                <FormattedMessage id="home_passport_title" />
              </TitleBold>
              <Title>
                <FormattedMessage id="home_passport_subtitle" />
              </Title>
            </TitleContainer>
          </Grid>
        </Grid>
        <Grid container rowSpacing={5}>
          <SidesContainer>
            <Grid item lg={6} xs={12}>
              <ColumnContainer>
                <ImageContainer src={silverTicket} />
                <Subtitle>
                  <FormattedMessage id="home_passport_silver_title" />
                </Subtitle>
                <Description>
                  <FormattedMessage id="home_passport_silver_description" />
                </Description>
              </ColumnContainer>
            </Grid>
            <PaddingCenter />
            <Grid item lg={6} xs={12}>
              <ColumnContainer>
                <ImageContainer src={goldTicket} />
                <Subtitle>
                  <FormattedMessage id="home_passport_gold_title" />
                </Subtitle>
                <Description>
                  <FormattedMessage id="home_passport_gold_description" />
                </Description>
              </ColumnContainer>
            </Grid>
          </SidesContainer>
        </Grid>
      </CenteredContainer>
    </SectionContainer>
  );
}

export default PassportSection;
