import { Grid } from '@mui/material';
import IconSports from 'assets/images/home/icon-1.png';
import IconEvents from 'assets/images/home/icon-2.png';
import IconConcerts from 'assets/images/home/icon-3.png';
import IconMuseums from 'assets/images/home/icon-4.png';
import IconAttractions from 'assets/images/home/icon-5.png';
import IconVenues from 'assets/images/home/icon-6.png';
import IconParks from 'assets/images/home/icon-7.png';
import IconCruises from 'assets/images/home/icon-8.png';
import IconZoos from 'assets/images/home/icon-9.png';
import { FormattedMessage } from 'react-intl';

import {
  CaseContainer,
  CaseImage,
  CaseTitle,
  CenteredContainer,
  SectionContainer,
  StyledContainerGrid,
  Title,
  TitleContainer,
  VerticalContainer,
} from './styles';

function UseCaseSection() {
  return (
    <SectionContainer>
      <CenteredContainer>
        <TitleContainer>
          <Title>
            <FormattedMessage id="home_usecases_title" />
          </Title>
        </TitleContainer>
        <VerticalContainer>
          <StyledContainerGrid container columnSpacing={12}>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconSports} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_sports" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconEvents} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_events" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconConcerts} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_concerts" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconMuseums} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_museums" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconAttractions} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_attractions" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconVenues} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_venues" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconParks} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_parks" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconCruises} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_cruises" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
            <Grid item lg={4} xs={6}>
              <CaseContainer>
                <CaseImage src={IconZoos} />
                <CaseTitle>
                  <FormattedMessage id="home_usecases_zoos" />
                </CaseTitle>
              </CaseContainer>
            </Grid>
          </StyledContainerGrid>
        </VerticalContainer>
      </CenteredContainer>
    </SectionContainer>
  );
}

export default UseCaseSection;
