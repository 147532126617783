import { motion } from 'framer-motion';
import styled from 'styled-components';

export const OpeningDiv = styled.div`
  width: 100%;
  height: auto;
  min-height: 85vh;
  position: relative;
`;

export const PicRight = styled.div`
  height: 85vh;
  width: 100%;

  @media only screen and (width < 1800px) {
    height: auto;
  }
`;

export const LearnMoreContainer = styled.div`
  height: 100%;
  top: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-left: 4rem;

  @media only screen and (width < 1800px) {
    height: auto;
    top: 0;
    padding-bottom: 3rem;
    padding-left: 0;
  }
`;

export const RightDiv = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  width: 100%;
  position: relative;

  @media only screen and (width < 1800px) {
    align-items: center;
    justify-content: center;
  }

  @media only screen and (width < 1200px) {
    margin: 0.5rem 0;
  }
`;

export const SaveTimeText = styled.div`
  height: 100%;
  width: 90%; 
  color: white;
  font-size: 60px;
  font-weight: bold;
  text-align: left;
  line-height: 1;

  @media only screen and (width < 1800px) {
    font-size: 50px;
  }

  @media only screen and (width < 1200px) {
    font-size: 25px;
    line-height: 1.2;
    padding-left: 0;
    text-align: center;
    width: 85%;
  }
`;

export const OfficialLaunchText = styled.div`
  height: 100%;
  width: 90%;
  color: white;
  font-size: 40px;
  padding-top: 1rem;
  text-align: left;
  text-transform: uppercase;

  @media only screen and (width < 1800px) {
    font-size: 30px;
  }

  @media only screen and (width < 1200px) {
    padding-left: 0;
    padding-top: 0;
    text-align: center;
    font-size: 20px;
    width: 80%;
  }
`;

export const LearnMoreButtonHolder = styled.div`
  height: 100%;
  width: 90%;
  color: white;
  font-size: 35px;
  padding-top: 2rem;
  text-align: left;

  @media only screen and (width < 1800px) {
    padding-top: 2rem;
    font-size: 25px;
  }

  @media only screen and (width < 1200px) {
    padding-left: 0;
    padding-top: 1rem;
    font-size: 20px;
    width: auto;
  }
`;

export const LearnMoreButton = styled(motion.div)`
  width: 20rem;
  height: 5rem;
  background: #F47142;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
  font-weight: bold;
  font-size: 31px;

  &:hover {
    cursor: pointer;
    background: darkorange;
  }

  @media only screen and (width < 1800px) {
    width: 15rem;
    height: 4rem;
    font-size: 25px;
  }

  @media only screen and (width < 1200px) {
    font-size: 20px;
  }
`;

export const EventOrganizerText = styled(motion.div)`
  height: 100%;
  width: 90%;
  color: white;
  font-size: 22px;
  padding-top: 1.5rem;
  text-align: left;
  font-weight: bold;
  
  &:hover {
    cursor: pointer;
  }

  @media only screen and (width < 1800px) {
    font-size: 18px;
  }

  @media only screen and (width < 1200px) {
    padding-left: 0;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    font-size: 15px;
    width: 80%;
  }
`;
