import { Grid } from '@mui/material';
import header_en from 'assets/images/home/bkg_header_EN.jpg';
import header_es from 'assets/images/home/bkg_header_ES.jpg';
import header_fr from 'assets/images/home/bkg_header_FR.jpg';
import MovonLogo from 'assets/images/logo/movon-couleur-horizontal-moyen.png';
import BackToTopButton from 'components/BackToTopButton/BackToTopButton';
import AccessSection from 'components/Home/AccessSection/AccessSection';
import FAQSection from 'components/Home/FAQSection/FAQSection';
import HomeFooter from 'components/Home/HomeFooter/HomeFooter';
import HomeHeader from 'components/Home/HomeHeader/HomeHeader';
import HomeIntro from 'components/Home/HomeIntro/HomeIntro';
import OrganizerSection from 'components/Home/OrganizerSection/OrganizerSection';
import PassportSection from 'components/Home/PassportSection/PassportSection';
import UseCaseSection from 'components/Home/UseCaseSection/UseCaseSection';
import VIPSection from 'components/Home/VIPSection/VIPSection';
import VisitorSection from 'components/Home/VisitorSection/VisitorSection';
import WorkSection from 'components/Home/WorkSection/WorkSection';
import LanguageContext from 'lib/providers/Language/LanguageContext';
import { useContext, useMemo, useRef } from 'react';

import {
  BlurBackgroundSection,
  CenteredContainer,
  Container,
  FirstSectionContainer,
  LogoContainer,
} from './styles';

function Home() {
  const { language } = useContext(LanguageContext);
  const top = useRef<HTMLDivElement>(null);
  const organizerSection = useRef<HTMLDivElement>(null);
  const visitorSection = useRef<HTMLDivElement>(null);
  const accessSection = useRef<HTMLDivElement>(null);
  const useCaseSection = useRef<HTMLDivElement>(null);
  const faqSection = useRef<HTMLDivElement>(null);

  const handleBackToTop = () => {
    top.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleScrollOrganizer = () => {
    organizerSection.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleScrollVisitor = () => {
    visitorSection.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleScrollAccess = () => {
    accessSection.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleScrollUseCase = () => {
    useCaseSection.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleScrollFAQ = () => {
    faqSection.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const backgroundImageLang = useMemo(() => {
    switch (language) {
      case 'es':
        return header_es;
      case 'fr':
        return header_fr;
      default:
        return header_en;
    }
  }, [language]);

  return (
    <Container>
      <div ref={top} />
      <BackToTopButton onClick={handleBackToTop} />
      <FirstSectionContainer
        $url={backgroundImageLang}
      >
        <Grid container>
          <Grid item xs={6} lg={5}>
            <CenteredContainer>
              <LogoContainer src={MovonLogo} />
            </CenteredContainer>
          </Grid>
          <Grid item xs={6} lg={7}>
            <HomeHeader
              handleScrollOrganizer={handleScrollOrganizer}
              handleScrollVisitor={handleScrollVisitor}
              handleScrollAccess={handleScrollAccess}
              handleScrollUseCase={handleScrollUseCase}
              handleScrollFAQ={handleScrollFAQ}
            />
          </Grid>
        </Grid>
        <HomeIntro
          handleScrollOrganizer={handleScrollOrganizer}
        />
      </FirstSectionContainer>
      <BlurBackgroundSection>
        <div ref={organizerSection}>
          <OrganizerSection />
        </div>
        <div ref={visitorSection}>
          <VisitorSection />
        </div>
      </BlurBackgroundSection>
      <div ref={accessSection}>
        <AccessSection />
      </div>
      <VIPSection />
      <PassportSection />
      <WorkSection />
      <div ref={useCaseSection}>
        <UseCaseSection />
      </div>
      <div ref={faqSection}>
        <FAQSection />
      </div>
      <HomeFooter />
    </Container>
  );
}

export default Home;
