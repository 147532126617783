import { Grid } from '@mui/material';
import ManWithCrown from 'assets/images/home/ManWithCrown.png';
import { FormattedMessage } from 'react-intl';

import { Centered } from '../shared';
import {
  CardsContainer,
  CardTextAccent,
  CenteredContainer,
  Description,
  DescriptionContainer,
  FullGrid,
  LeftCard,
  LeftCardImage,
  LeftCardRightSide,
  LeftCardSide,
  LeftCardText,
  RightCard,
  RightCardText,
  SectionContainer,
  Title,
  TitleContainer,
  VIPTitle,
} from './styles';

function AccessSection() {
  return (
    <SectionContainer>
      <CenteredContainer>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Centered>
              <TitleContainer>
                <Title>
                  <FormattedMessage id="home_access_title_part_1" />
                  <VIPTitle>.VIP</VIPTitle>
                  <FormattedMessage id="home_access_title_part_2" />
                </Title>
              </TitleContainer>
            </Centered>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Centered>
              <DescriptionContainer>
                <Description>
                  <FormattedMessage id="home_access_description" />
                </Description>
              </DescriptionContainer>
            </Centered>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Grid container>
              <CardsContainer>
                <Grid item lg={12} xs={6}>
                  <Centered>
                    <LeftCard>
                      <FullGrid container>
                        <Grid item lg={4} xs={12}>
                          <LeftCardSide>
                            <LeftCardImage src={ManWithCrown} />
                          </LeftCardSide>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          <LeftCardRightSide>
                            <LeftCardText>
                              <FormattedMessage id="home_access_left_card_part_1" />
                              <CardTextAccent>
                                <FormattedMessage id="home_access_left_card_visitors" />
                              </CardTextAccent>
                              <FormattedMessage id="home_access_left_card_part_2" />
                            </LeftCardText>
                          </LeftCardRightSide>
                        </Grid>
                      </FullGrid>
                    </LeftCard>
                  </Centered>
                </Grid>
                <Grid item lg={12} xs={6}>
                  <Centered>
                    <RightCard>
                      <RightCardText>
                        <FormattedMessage id="home_access_right_card_part_1" />
                        <CardTextAccent>
                          <FormattedMessage id="home_access_right_card_organizers" />
                        </CardTextAccent>
                        <FormattedMessage id="home_access_right_card_part_2" />
                      </RightCardText>
                    </RightCard>
                  </Centered>
                </Grid>
              </CardsContainer>
            </Grid>
          </Grid>
        </Grid>
      </CenteredContainer>
    </SectionContainer>
  );
}

export default AccessSection;
