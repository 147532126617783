import { Grid } from '@mui/material';
import FAQBkgEn from 'assets/images/home/bkg_faq_en.jpg';
import FAQBkgEs from 'assets/images/home/bkg_faq_es.jpg';
import LanguageContext from 'lib/providers/Language/LanguageContext';
import { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Question from './Question';
import {
  BottomPadding,
  CenteredContainer,
  ColumnContainer,
  Description,
  FAQ,
  FAQDescription,
  GoldenSpan,
  ImageBkg,
  Line,
  SectionContainer,
} from './styles';

function FAQSection() {
  const { language } = useContext(LanguageContext);

  const FAQBkg = useMemo(() => {
    switch (language) {
      case 'es':
        return FAQBkgEs;
      case 'fr':
        return FAQBkgEn;
      default:
        return FAQBkgEn;
    }
  }, [language]);

  return (
    <SectionContainer>
      <ImageBkg src={FAQBkg} />
      <CenteredContainer>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <ColumnContainer>
              <FAQ>
                <FormattedMessage id="home_faq_title" />
              </FAQ>
              <FAQDescription>
                <FormattedMessage id="home_faq_description" />
              </FAQDescription>
            </ColumnContainer>
          </Grid>
          <Grid item lg={12} xs={12}>
            <ColumnContainer>
              <Question
                question="home_faq_subtitle_1"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_1" />
                  </Description>
                )}
              />
              <Line />
              <Question
                question="home_faq_subtitle_2"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_2" />
                  </Description>
                )}
              />
              <Line />
              <Question
                question="home_faq_subtitle_3"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_3" />
                  </Description>
                )}
              />
              <Line />
              <Question
                question="home_faq_subtitle_4"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_4" />
                    <ul>
                      <li><FormattedMessage id="home_faq_goal_1" /></li>
                      <li><FormattedMessage id="home_faq_goal_2" /></li>
                      <li><FormattedMessage id="home_faq_goal_3" /></li>
                      <li><FormattedMessage id="home_faq_goal_4" /></li>
                      <li><FormattedMessage id="home_faq_goal_5" /></li>
                      <li><FormattedMessage id="home_faq_goal_6" /></li>
                      <li><FormattedMessage id="home_faq_goal_7" /></li>
                      <li><FormattedMessage id="home_faq_goal_8" /></li>
                      <li><FormattedMessage id="home_faq_goal_9" /></li>
                      <li><FormattedMessage id="home_faq_goal_10" /></li>
                      <li><FormattedMessage id="home_faq_goal_11" /></li>
                      <li><FormattedMessage id="home_faq_goal_12" /></li>
                      <li><FormattedMessage id="home_faq_goal_13" /></li>
                      <li><FormattedMessage id="home_faq_goal_14" /></li>
                      <li><FormattedMessage id="home_faq_goal_15" /></li>
                      <li><FormattedMessage id="home_faq_goal_16" /></li>
                      <li><FormattedMessage id="home_faq_goal_17" /></li>
                    </ul>
                  </Description>
                )}
              />
              <Line />
              <Question
                question="home_faq_subtitle_5"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_5_part_1" />
                    <GoldenSpan> 25% </GoldenSpan>
                    <FormattedMessage id="home_faq_answer_5_part_2" />
                  </Description>
                )}
              />
              <Line />
              <Question
                question="home_faq_subtitle_6"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_6" />
                  </Description>
                )}
              />
              <Line />
              { /*
                // Commenté puisqu'il y a un lien qui n'est toujours pas accessible au public ATM.
                // À rajouter une fois accessible.
                <Question
                  question="home_faq_subtitle_7"
                  answer={(
                    <Description>
                      <FormattedMessage id="home_faq_answer_7" />
                    </Description>
                  )}
                />
                <Line />
              */ }
              <Question
                question="home_faq_subtitle_8"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_8" />
                  </Description>
                )}
              />
              <Line />
              <Question
                question="home_faq_subtitle_9"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_9" />
                  </Description>
                )}
              />
              <Line />
              <Question
                question="home_faq_subtitle_10"
                answer={(
                  <Description>
                    <FormattedMessage id="home_faq_answer_10_part_1" />
                    <GoldenSpan> 33% </GoldenSpan>
                    <FormattedMessage id="home_faq_answer_10_part_2" />
                  </Description>
                )}
              />
              <BottomPadding />
            </ColumnContainer>
          </Grid>
        </Grid>
      </CenteredContainer>
    </SectionContainer>
  );
}

export default FAQSection;

