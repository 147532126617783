import { Grid } from '@mui/material';
import lineImgEn from 'assets/images/home/illustration_lineup_EN.png';
import lineImgEs from 'assets/images/home/illustration_lineup_ES.png';
import lineImgFr from 'assets/images/home/illustration_lineup_FR.png';
import RunningIcon from 'assets/images/home/Running_icon.png';
import LanguageContext from 'lib/providers/Language/LanguageContext';
import { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Benefits,
  BulletPoint,
  BulletPoints,
  BulletText,
} from '../OrganizerSection/styles';
import {
  CenteredContainer,
  CenteredImageContainer,
  ImageContainer,
  LeftSideContainer,
  QuestionText,
  QuestionTextAccent,
  QueueContainer,
  QueuePoint,
  QueueText,
  QueueVipText,
  ThirdSectionContainer,
  TitleContainer,
  VisitorImgContainer,
  VisitorLine,
  VisitorQuestion,
  VisitorTitle,
  VisitorTitleAccent,
  VisitorTitleImg,
} from './styles';

function VisitorSection() {
  const { language } = useContext(LanguageContext);

  const lineImg = useMemo(() => {
    switch (language) {
      case 'es':
        return lineImgEs;
      case 'fr':
        return lineImgFr;
      default:
        return lineImgEn;
    }
  }, [language]);

  return (
    <ThirdSectionContainer>
      <Grid container>
        <Grid item xs={12} lg={6.5}>
          <CenteredContainer>
            <VisitorLine />
            <VisitorQuestion>
              <QuestionText>
                <FormattedMessage id="home_visitor_subtitle_part_1" />
                <QuestionTextAccent>
                  <FormattedMessage id="home_visitor_subtitle_visitors" />
                </QuestionTextAccent>
                <FormattedMessage id="home_visitor_subtitle_part_2" />
              </QuestionText>
            </VisitorQuestion>
            <LeftSideContainer>
              <TitleContainer>
                <VisitorTitle>
                  <FormattedMessage id="home_visitor_title_part_1" />
                  <VisitorTitleAccent>
                    <VisitorImgContainer>
                      <VisitorTitleImg src={RunningIcon} />
                    </VisitorImgContainer>
                    <FormattedMessage id="home_visitor_title_faster" />
                  </VisitorTitleAccent>
                  <FormattedMessage id="home_visitor_title_part_2" />
                  <VisitorTitleAccent>
                    <FormattedMessage id="home_visitor_title_planet" />
                  </VisitorTitleAccent>
                  !
                </VisitorTitle>
                <Benefits>
                  <FormattedMessage id="home_visitor_benefits" />
                </Benefits>
              </TitleContainer>
              <BulletPoints>
                <ul>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_visitor_benefit_1" />
                    </BulletText>
                  </BulletPoint>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_visitor_benefit_2" />
                    </BulletText>
                  </BulletPoint>
                  <QueueContainer>
                    <QueuePoint>
                      <QueueText>
                        <QueueVipText>
                          <FormattedMessage id="home_visitor_vip_silver" />
                        </QueueVipText>
                        <FormattedMessage id="home_visitor_benefit_3" />
                      </QueueText>
                    </QueuePoint>
                    <QueuePoint>
                      <QueueText>
                        <QueueVipText>
                          <FormattedMessage id="home_visitor_vip_gold" />
                        </QueueVipText>
                        <FormattedMessage id="home_visitor_benefit_4" />
                      </QueueText>
                    </QueuePoint>
                  </QueueContainer>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_visitor_benefit_5" />
                    </BulletText>
                  </BulletPoint>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_visitor_benefit_6" />
                    </BulletText>
                  </BulletPoint>
                </ul>
              </BulletPoints>
            </LeftSideContainer>
          </CenteredContainer>
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <CenteredImageContainer>
            <ImageContainer src={lineImg} />
          </CenteredImageContainer>
        </Grid>
      </Grid>
    </ThirdSectionContainer>
  );
}

export default VisitorSection;
