import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import LanguageProvider from 'lib/providers/Language/LanguageProvider';
import AppThemeProvider from 'styles/AppThemeProvider';

import Routes from '../routes/routes';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline enableColorScheme />
      <AppThemeProvider>
        <LanguageProvider>
          <Routes />
        </LanguageProvider>
      </AppThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
