import { Button, Menu, MenuItem } from '@mui/material';
import styled from 'styled-components';

export const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const DropdownTrigger = styled(Button)`
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background: #00888a;
  box-shadow: 0 0 10px 1px #363738;

  &:hover {
    background: #00888a;
    opacity: 1;
  }
`;

export const DropdownText = styled.div`
  color: white;
  font-weight: bold;
`;

export const IconContainer = styled.div`
  padding-left: 10px;
  color: white;
`;

export const MenuItemStyled = styled(MenuItem)`
  background: #00888a;
  color: white;
  width: 100%;
`;

export const MenuStyled = styled(Menu)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
