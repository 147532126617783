import { motion } from 'framer-motion';
import styled from 'styled-components';

export const BackToTopContainer = styled(motion.div)`
  position: fixed;
  bottom: 30px;
  right: 30px; 
  width: 70px;
  height: 4%;
  font-size: 25px;
  color: #FFFFFF80;
  z-index: 20;
  flex-direction: row;
  cursor:pointer;
`;

export const BackToTopText = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 10px;
  border: none;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main}90;
  }
`;
