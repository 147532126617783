import styled from 'styled-components';

export const SecondSectionContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  background-size: cover;

  @media only screen and (width < 1800px) {
    height: auto;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    padding: 0 1.5rem;
  }
`;

export const ImageContainer = styled.div`
  width: 85%;
  height: 70%;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    width: 140%;
  }
`;

export const StandImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const OrganizerLine = styled.div`
  position: absolute;
  top: 5rem;
  left: 0;
  width: 11rem;
  height: 3px;
  background: teal;

  @media only screen and (width < 1200px){
    width: 2rem;
    height: 1px;
  }
`;

export const OrganizerQuestion = styled.div`
  position: absolute;
  top: 3rem;
  left: 13rem;
  width: 30rem;
  height: auto;

  @media only screen and (width < 1200px) {
    left: 1rem;
    width: 25rem;
    padding: 0 1.5rem;
  }
`;

export const QuestionText = styled.div`
  font-size: 23px;
  color: teal;
  text-transform: uppercase;

  @media only screen and (width < 1200px) {
    width: 85%;
    font-size: 16px;
  }
`;

export const QuestionTextAccent = styled.span`
  font-size: 23px;
  color: teal;
  font-weight: bold;
  text-transform: uppercase;

  @media only screen and (width < 1200px) {
    font-size: 16px;
  }
`;

export const LeftSideContainer = styled.div`
  padding-top: 12rem;
  padding-left: 13rem;

  @media only screen and (width < 1200px) {
    padding-left: 0;
    padding-top: 10rem;
  }
`;

export const TitleContainer = styled.div`
  width: 80%;
  height: auto;

  @media only screen and (width < 1800px) {
    width: 95%;
  }

  @media only screen and (width < 1200px) {
    padding-left: 1rem;
  }
`;

export const Title = styled.div`
  font-size: 50px;
  color: black;
  font-weight: bold;
  line-height: 1.2;

  @media only screen and (width < 1200px) {
    font-size: 30px;
  }
`;

export const TitleAccent = styled.span`
  font-size: 50px;
  color: teal;
  font-weight: bold;
  line-height: 1.2;

  @media only screen and (width < 1200px) {
    font-size: 30px;
  }
`;

export const Benefits = styled.div`
  font-size: 35px;
  color: black;
  font-weight: bold;
  padding-top: 1rem;

  @media only screen and (width < 1200px) {
    font-size: 22px;
  }
`;

export const BulletPoints = styled.div`
  width: 90%;
  height: auto;
  padding-left: 1rem;
`;

export const BulletPoint = styled.li`
  color: teal;
  font-size: 28px;
  line-height: 1.2;
  padding-bottom: 12px;

  @media only screen and (width < 1200px) {
    font-size: 18px;
  }
`;

export const BulletText = styled.span`
  color: black;
  font-size: 24px;

  @media only screen and (width < 1200px) {
    font-size: 18px;
  }
`;
