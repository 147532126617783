import DegradeBkg from 'assets/images/home/bkg_section_degrade.png';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  width: 100%;
  height: 170vh;
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: grey;
  flex-direction: column;
  background-image: url(${DegradeBkg});
  background-size: contain;
  background-repeat: repeat-y;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    overflow-y: hidden;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
  overflow-y: visible;
  position: relative;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    width: 100%;
  }
`;

export const ColumnContainer = styled.div`
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const LeftTextContainer = styled.div`
  text-align: left;
`;

export const TitleContainer = styled.div`
  font-size: 50px;
  color: white;
  font-weight: bold;
  padding-top: 3rem;
  padding-bottom: 5rem;
  text-align: left;

  @media only screen and (width < 1200px) {
    padding-bottom: 0;
    text-align: center;
    font-size: 35px;
  }
`;

export const Subtitle = styled.div`
  font-size: 30px;
  color: white;
  font-weight: bold;
  padding-bottom: 2rem;
  padding-left: 2rem;

  @media only screen and (width < 1200px) {
    padding-top: 2rem;
    font-size: 24px;
  }
`;

export const Circle = styled.img`
  height: 5.5rem;
  width: 5.5rem;
  margin-right: 1rem;
  position: relative;
  top: 0;
  left: -20px;

  @media only screen and (width < 1200px) {
    height: 3.5rem;
    width: 3.5rem;
    left: -5px;
    top: -15px;
  }
`;

export const PointContainer = styled.div`
  width: 100%;
  height: auto;

  @media only screen and (width < 1200px) {
    padding: 0.5rem 0;
  }
`;

export const TextContainer = styled.div`
  width: 95%;
  position: relative;
  left: 2rem;
  top: -4rem;
  margin-bottom: -3rem;
  color: white;
  text-align: left;
  font-size: 27px;

  @media only screen and (width < 1200px) {
    font-size: 20px;
    width: 85%;
  }
`;

export const ImageContainer = styled.img`
  width: 45rem;
  height: auto;
  position: absolute;
  left: 0;
  bottom: -5rem;

  @media only screen and (width > 2000px) {
    width: 55rem;
  }

  @media only screen and (width < 1800px) {
    width: 90%;
    height: auto;
    position: relative;
    top: 0;
    left: -5rem;
  }

  @media only screen and (width < 1200px) {
    left: 0;
    width: 100%;
  }
`;

export const TopCurve = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
`;
