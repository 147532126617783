const messages: I18NMessages = {
  about_novom: 'À propos de Novom',

  cart_choose_for_me: 'Choisir pour moi',
  cart_choose: 'Je veux choisir',
  cart_summary: 'Résumé',
  cart_impact: 'Impact',
  cart_impact_description: 'Pour chaque billet MovOn VIP vendu',
  cart_impact_follow: '33% seront versés à une cause charitable',
  cart_payment: 'Paiement',
  cart_phone: 'Numéro de téléphone',
  cart_email: 'Adresse courriel',
  cart_info_missing: 'Information manquante',
  cart_phone_invalid: 'Numéro de téléphone invalide',
  cart_email_invalid: 'Adresse courriel invalide',
  cart_email_instead: 'Adresse courriel',
  cart_phone_instead: 'Numéro de téléphone',
  cart_more_options: 'Plus d\'options',
  cart_nickname: 'Prénom ou surnom (optionnel)',
  cart_pay_google: 'Payer avec G Pay',
  cart_pay_credit: 'Payer avec carte de crédit',
  cart_pay_total: 'Total',
  cart_remove: 'Retirer',
  cart_subtotal: 'Sous-total',
  cart_tax: 'Taxes',

  general_back: 'Retour',
  general_next: 'Suivant',
  general_close: 'Fermer',
  general_select: 'Choisir',

  home_access_title_part_1: 'Qu\'est-ce que l\'accès MovOn',
  home_access_title_part_2: '?',
  home_access_description: 'MovOn incarne l\'idée d\'un mouvement perpétuel vers l\'avant. Ce concept s\'étend '
   + 'au-delà d\'une simple invitation au mouvement physique, mais englobant plutôt une progression constante et collective',
  home_access_left_card_part_1: 'Pour les ',
  home_access_left_card_part_2: ' d\'événements culturels, sportifs ou d\'attractions touristiques, MovOn incite '
  + 'à aller au-delà de l\'expérience et à embrasser l\'idée que chaque moment est une opportunité de croissance '
  + 'et d\'amélioration de notre bien-être collectif.',
  home_access_left_card_visitors: 'visiteurs',
  home_access_right_card_part_1: 'Pour vous, ',
  home_access_right_card_part_2: ', MovOn est un appel à l\'innovation, à l\'adaptabilité et à la recherche '
  + 'constante des moyens d\'améliorer et de transformer les expériences que vous pouvez offrir.',
  home_access_right_card_organizers: 'organisateurs',

  home_faq_title: 'Foire aux questions (FAQ)',
  home_faq_description: 'Bienvenue dans notre section FAQ! Trouvez ici les réponses aux questions fréquemment '
  + 'posées sur nos produits, services et politiques. Si vous avez d\'autres interrogations, '
  + 'n\'hésitez pas à nous contacter. Nous sommes là pour simplifier votre expérience.',
  home_faq_subtitle_1: 'MovOn.VIP vous est-il destiné?',
  home_faq_answer_1: 'Vous êtes gestionnaire d\'attraction ou organisateur d\'événement, et il peut y avoir du '
  + 'temps d\'attente à l\'accueil? MovOn.VIP est tout indiqué pour vous. A titre d\'exemples, vous pouvez être '
  + 'dans un des contextes suivants: grands événements, concerts et festivals, stades sportifs, musées et '
  + 'expositions, attractions urbaines grand public, salles de spectacle (admission générale), parcs '
  + 'd\'attractions, fêtes populaires, croisières, visites en excursion, parc naturels, zoos, ou tout autre '
  + 'événement spécial.',
  home_faq_subtitle_2: 'Que signifie "MovOn"?',
  home_faq_answer_2: 'MovOn signifie “Move on” en anglais. Ceci incarne l\'idée d\'un mouvement perpétuel vers '
  + 'l\'avant. Ce concept s\'étend au-delà d\'une simple invitation au déplacement physique, englobant plutôt '
  + 'une progression constante et collective.',
  home_faq_subtitle_3: 'Que signifie "V.I.P."?',
  home_faq_answer_3: 'Pour nous, VIP veut dire “Very Important Planet!”. Ceci va au-delà de la notion traditionnelle '
  + 'de statut privilégié. En adoptant cet acronyme, l\'accent est mis sur l\'importance cruciale de notre planète '
  + 'et sur la responsabilité de chacun d\'entre nous envers l\'humanité.',
  home_faq_subtitle_4: 'Quels sont les 17 objectifs de développement durable des Nations Unies?',
  home_faq_answer_4: 'Les 17 Objectifs de Développement Durable (ODD) de l\'ONU sont un ensemble d\'objectifs mondiaux '
  + 'établis pour aborder des défis majeurs tels que la pauvreté, l\'inégalité, le changement climatique, la paix, '
  + 'et la justice. Ces objectifs ont été adoptés en septembre 2015 dans le cadre du Programme de développement durable '
  + 'à l\'horizon 2030. Voici la liste des 17 ODD :',
  home_faq_subtitle_5: 'Combien cela coûte-t-il pour vous, en tant qu\'organisateur?',
  home_faq_answer_5_part_1: 'Non seulement MovOn.VIP ne vous coûte rien, mais il s\'agit pour vous d\'une source de '
  + 'revenus supplémentaire pour vous! En effet, vous recevez',
  home_faq_answer_5_part_2: 'de tous les fonds MovOn.VIP collectés lors de votre événement/attraction!',
  home_faq_subtitle_6: 'En quelle langue est offert MovOn.VIP?',
  home_faq_answer_6: 'Le service MovOn.VIP est international. Il est actuellement disponible en anglais, français '
  + 'et espagnol. D\'autres langues seront offertes sous peu.',
  home_faq_subtitle_7: 'Comme visiteur et utilisateur, dois-je installer une Application Mobile?',
  home_faq_answer_7: 'Non. Il n\'y a aucune application mobile à installer. Tout se passe en ligne, en allant sur '
  + 'www.MovOn.VIP/access avec votre téléphone mobile ou votre tablette.',
  home_faq_subtitle_8: 'Comme organisateur ou gestionnaire, dois-je installer un logiciel?',
  home_faq_answer_8: 'Non. Il n\'y a aucun logiciel à installer: tout est sécurisé et en ligne. Que ce soit pour créer '
  + 'votre compte organisationnel, entrer les informations de votre événement/attraction, gérer et contrôler les accès '
  + 'avec code QR, gérer les rapports d\'événements détaillés, et finalement recevoir votre part des montants recueillis.',
  home_faq_subtitle_9: 'Qui est Novom Interactive?',
  home_faq_answer_9: 'Novom Interactive est une entreprise canadienne, une “spin-off” universitaire fondée en 2012, et '
  + 'rassemblant une belle équipe de chercheurs universitaires, de créateurs artistiques, d\'experts en attractions et '
  + 'événements, ainsi que de développeurs logiciels d\'expérience. Notre équipe est derrière la conception du produit MovOn.VIP, une '
  + 'solution se voulant simple et efficace, à la fois avant-gardiste et originale. Cette initiative découle d\'une '
  + 'volonté partagée au sein du groupe, accompagnée d\'un sentiment d\'urgence en vue d\'améliorer notre planète, et '
  + 'à favoriser le bien-être des êtres humains qui l\'habitent. Avec MovOn.VIP, nous aspirons à créer une plateforme '
  + 'engagée, propice à l\'avancement collectif et à la réalisation d\'objectifs durables, en vue d\'un avenir meilleur '
  + 'pour tous.',
  home_faq_subtitle_10: 'Lors d\'un événement ou pour une attraction, quel pourcentage des revenus recueillis est '
  + 'redistribué à des OBNL adhérant aux objectifs de développement durable?',
  home_faq_answer_10_part_1: 'Sur tous les fonds collectés par l\'utilisation de MovOn.VIP lors d\'un événement ou pour une attraction,',
  home_faq_answer_10_part_2: 'sont reversés à des OBNL adhérant aux objectifs de développement durable.'
  + '(ODD) des Nations Unies.',
  home_faq_goal_1: 'Pas de pauvreté',
  home_faq_goal_2: 'Faim "zéro"',
  home_faq_goal_3: 'Bonne santé et bien-être',
  home_faq_goal_4: 'Éducation de qualité',
  home_faq_goal_5: 'Égalité entre les sexes',
  home_faq_goal_6: 'Eau propre et assainissement',
  home_faq_goal_7: 'Énergie propre et d\'un coût abordable',
  home_faq_goal_8: 'Travail décent et croissance économique',
  home_faq_goal_9: 'Industrie, innovation et infrastructure',
  home_faq_goal_10: 'Inégalités réduites',
  home_faq_goal_11: 'Villes et communautés durables',
  home_faq_goal_12: 'Consommation et production responsables',
  home_faq_goal_13: 'Mesures relatives à la lutte contre les changements climatiques',
  home_faq_goal_14: 'Vie aquatique',
  home_faq_goal_15: 'Vie terrestre',
  home_faq_goal_16: 'Paix, justice et institutions efficaces',
  home_faq_goal_17: 'Partenariats pour la réalisation des objectifs',

  home_footer_rights: '©Movon.VIP {year} - Fière création canadienne',
  home_footer_submit: 'Envoyer',
  home_footer_partners: 'Nos partenaires',
  home_footer_touch: 'Rejoignez-nous',
  home_footer_name: 'Nom',
  home_footer_email: 'Courriel',
  home_footer_message: 'Message',

  home_intro_title: 'Gagnez un temps précieux en évitant les attentes',
  home_intro_subtitle: 'Lancement officiel à l\'été 2024',
  home_intro_button: 'En savoir plus',
  home_intro_organizer: 'Vous êtes un organisateur d\'événements?',

  home_nav_about: 'À propos',
  home_nav_faq: 'FAQ',
  home_nav_organizers: 'Organisateurs',
  home_nav_use_cases: 'Cas d\'utilisations',
  home_nav_visitors: 'Visiteurs',

  home_organizer_question_part_1: 'Êtes-vous un ',
  home_organizer_question_part_2: ' d\'événements ou gestionnaire d\'attraction touristique?',
  home_organizer_question_organizer: 'organisateur',
  home_organizer_title_part_1: 'Augmentez vos revenus et améliorez l\'',
  home_organizer_title_part_2: ' client!',
  home_organizer_title_experience: 'expérience',
  home_organizer_benefits: 'Les avantages:',
  home_organizer_benefit_1: 'Source de revenus supplémentaire pour vous (hé oui!)',
  home_organizer_benefit_2: 'Traitement VIP pour vos visiteurs qui le souhaitent, dès leur arrivée',
  home_organizer_benefit_3: 'Contribution automatique aux objectifs de développement durable',
  home_organizer_benefit_4: 'Grande facilité et simplicité de mise en oeuvre du système',
  home_organizer_benefit_5: 'Des visiteurs plus satisfaits, et adhérant à votre vision humanitaire',

  home_passport_title: 'Passeport VIP Silver ou Gold:',
  home_passport_subtitle: 'Lequel vous convient?',
  home_passport_silver_title: 'Accès accéléré.',
  home_passport_gold_title: 'Accès immédiat.',
  home_passport_silver_description: 'Avec un accès VIP Silver, vous attendez où bon vous semble, et non '
  + 'dans une longue file d\'attente avant d\'accéder à l\'événement ou l\'attraction. Alors, prenez ce '
  + 'temps pour vous, pour faire autre chose de plus utile que d\'attendre dans une file. Intéressant non?',
  home_passport_gold_description: 'Avec un accès VIP Gold, encore mieux, vous n\'avez pas à attendre du '
  + 'tout! Dès que le gestionnaire de l\'endroit le permet, les portes vous sont ouvertes et vous pouvez '
  + 'entrer dès votre arrivée, peu importe le moment. Cool n\'est-ce pas?',

  home_usecases_title: 'Quels sont les événements pouvant bénéficier de l\'accès privilégié MovOn.VIP?',
  home_usecases_sports: 'Stades sportifs',
  home_usecases_events: 'Grands événements',
  home_usecases_concerts: 'Concerts et festivals',
  home_usecases_museums: 'Musées et expositions',
  home_usecases_attractions: 'Attractions urbaines grand publique',
  home_usecases_venues: 'Salles de spectacle (admission générale)',
  home_usecases_parks: 'Parcs d\'attraction et fêtes populaires',
  home_usecases_cruises: 'Croisières et visites en excursions',
  home_usecases_zoos: 'Parcs naturels et zoos',

  home_vip_title: 'VIP (Very Important Planet!)',
  home_vip_description: 'VIP va au-delà de la notion traditionnelle de statut privilégié. En adoptant '
  + 'cet acronyme, l\'accent est mis sur l\'importance cruciale de notre planète et la responsabilité '
  + 'de chacun de nous a envers l\'humanité.',
  home_vip_description_2_part_1: 'Être "',
  home_vip_description_2_part_2: '" implique de mettre de l\'avant les contributions et les actions '
  + 'nécessaires pour améliorer notre planète, en alignement avec les 17 objectifs de développement durable des Nations Unies. '
  + 'Ces objectifs, tel que la lutte contre le changement climatique, l\'accès à l\'éducation de qualité, '
  + 'la promotion de l\'égalité des genres et l\'éradication de la pauvreté, deviennent les piliers de la vision '
  + '"Very Important Planet".',
  home_vip_goals: 'En embrassant ces objectifs, chaque individu devient un acteur essentiel dans la préservation '
  + 'de notre planète et dans la construction d\'un avenir plus durable et équitable pour tous.',

  home_visitor_subtitle_part_1: 'Vous êtes ',
  home_visitor_subtitle_part_2: ' d\'un événement culturel ou d\'une attraction touristique?',
  home_visitor_subtitle_visitors: 'visiteur',
  home_visitor_title_part_1: 'Profitez d\'un accès plus ',
  home_visitor_title_part_2: ' et aidez notre ',
  home_visitor_title_faster: 'rapide',
  home_visitor_title_planet: 'planète',
  home_visitor_benefits: 'Les avantages:',
  home_visitor_benefit_1: 'Terminé les temps d\'attente sur place - Entrez immédiatement!',
  home_visitor_benefit_2: 'Profitez d\'un accueil VIP pour accéder à votre événement/attraction:',
  home_visitor_benefit_3: 'attendre où bon vous semble; puis au bon moment, recevez votre notification d\'entrer.',
  home_visitor_benefit_4: 'votre accès est immédiat et prioritaire, peu importe le moment où vous arrivez.',
  home_visitor_benefit_5: 'Votre micropaiement VIP contribue aussi à aider la planète',
  home_visitor_benefit_6: 'Vivez seulement une belle expérience, de A à Z, sans les possibles désagréments',
  home_visitor_vip_gold: 'VIP Gold: ',
  home_visitor_vip_silver: 'VIP Silver: ',

  home_work_title: 'Comment ça marche?',
  home_work_visitor: 'Vous êtes visiteur?',
  home_work_organizer: 'Vous êtes organisateur?',
  home_work_visitor_1: 'C\'est très simple. Sur www.MovOn.VIP/access avec votre téléphone (aucune application '
  + 'à installer), vous choisissez votre événement/attraction.',
  home_work_visitor_2: 'Avec votre micropaiement, vous indiquez votre prénom et votre numéro de cellulaire (aucun '
  + 'compte utilisateur à créer). Vous recevez alors une confirmation et un code QR.',
  home_work_visitor_3: 'Vous êtes alors dans la “fast track”, en attente d\'une notification SMS vous invitant à '
  + 'vous rendre à l\'entrée, lorsque c\'est pratiquement votre tour d\'entrer.',
  home_work_visitor_4: 'Arrivé à l\'accueil, montrez simplement votre code QR, et profitez alors d\'une entrée '
  + 'immédiate. Simple non?',
  home_work_organizer_1: 'C\'est simple. Sur www.MovOn.VIP/account avec ordinateur ou tablette, vous saisissez '
  + 'les informations demandées pour créer votre événement/attraction. Vous aurez ensuite accès à la section '
  + 'www.MovOn.VIP/manager dans laquelle vous pourrez démarrer la gestion des accès VIP pour vos visiteurs.',
  home_work_organizer_2: 'Ensuite, au fur et à mesure que vos visiteurs s\'inscrivent comme visiteurs VIP '
  + '(Silver ou Gold), vous les verrez apparaître dans votre page de gestion des accès.',
  home_work_organizer_3: 'Une page web adaptative (“responsive”) www.MovOn.VIP/QRcode vous sera également '
  + 'fournie pour le “Jour J”, vous permettant de lire les codes QR sur les cellulaires de vos visiteurs, '
  + 'au fur et à mesure qu\'ils arriveront à l\'accueil. Ceci vous permettra de valider leur accès VIP et '
  + 'leur donner accès. Toute la gestion des flux financiers est automatiquement gérée par la plateforme.',
  home_work_organizer_4: 'Et dès la fin de l\'événement, vous accédez à un rapport détaillé en ligne, '
  + 'ainsi qu\'à la somme récoltée par transfert bancaire. Il n\'y a aucun logiciel à installer: tout '
  + 'est sécurisé et en ligne. N\'est-ce pas merveilleux?',

  onboarding_cause: 'Soutenez une cause qui vous tient à coeur.',
  onboarding_obtain: 'Obtenez un accès VIP',

  movon_title: 'MovOn.VIP',

  pass_congratulations: 'Félicitations {name}!',
  pass_download_pass: 'ou téléchargez votre pass',
  pass_owner_gold: 'Pass VIP Gold de {name}',
  pass_owner_silver: 'Pass VIP Silver de {name}',
  pass_owner_silver_no_name: 'Votre Pass VIP Silver',
  pass_owner_gold_no_name: 'Votre Pass VIP Gold',
  pass_gold_vip: 'Vous êtes désormais VIP Gold',
  pass_silver_vip: 'Vous êtes désormais VIP Silver',
  pass_help: 'Besoin d\'aide?',
  pass_line_status: 'Status de la file d\'attente',
  pass_resend_confirmation: 'Renvoyer la confirmation',
  pass_sent_to: 'Votre pass a été envoyé vers {email}',
  pass_show_pass: 'Montrez votre pass à un caissier pour sauter la file d\'attente',
  pass_what_next: 'Prochaine étape?',
  pass_qr_wallet: 'Ajouter au Google Wallet',

  payment_information: 'Informations de paiement',

  terms_conditions: 'Conditions générales',

  tickets_checkout: 'Caisse',
  tickets_gold_title: 'Sautez la file d\'attente',
  tickets_silver_title: 'Sauvez votre place',
  tickets_gold_description: 'Le pass VIP Gold vous donne un accès immédiat à l\'attraction.',
  tickets_group_size: 'Max: ',
  tickets_group_size_2: '{limit} billet{limit, plural, one {} other {s}}',
  tickets_available: '{quantity} disponible',
  tickets_quantity: 'Quantité',
};

export default messages;
