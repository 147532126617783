import { Grid, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 5;

  @media only screen and (width < 1200px) {
    height: auto;
  }
`;

// Display: none => Le formulaire ne sera pas instauré pour le moment.
// Une fois la fonctionnalité d'envoie d'email envers Novom prête il sera en ligne.
// https://app.shortcut.com/novom/story/6524/form-submit-dans-nous-joindre-sur-la-home-page
export const FormSectionContainer = styled.div`
  width: 100%;
  height: 50rem;
  display: none;
  align-items: center;
  justify-content: center;
  background: #40ADB1;
  flex-direction: column;
  z-index: 0;
`;

export const TopFooter = styled.div`
  width: 100%;
  height: 17rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  flex-direction: column;
  z-index: 5;
  
  @media only screen and (width < 1200px) {
    height: auto;
  }
`;

export const PartnersTitle = styled.div`
  font-size: 24px;
  color: teal;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  margin: 2rem 0;
`;

export const PartnersContainer = styled.div`
  width: 60%;
  height: 7rem;

  @media only screen and (width > 2000px) {
    height: 10rem;
  }

  @media only screen and (width < 1200px) {
    height: auto;
    width: 80%;
    padding-bottom: 3rem;
  }
`;

export const PartnerContainer = styled(Grid)`
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (width < 1800px) {
    height: auto;
  }
`;

export const PartnerLogo = styled.img`
  width: 60%;
  height: auto;
`;

export const PCANLogo = styled.img`
  height: auto;
  width: 80%;

  @media only screen and (width < 1200px) {
    width: 65%;
  }
`;

export const BottomFooter = styled.div`
  height: 8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #062C3F;

  @media only screen and (width < 1200px) {
    height: auto;
  }
`;

export const CenteredBottomGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8rem;
  background: #062C3F;

  @media only screen and (width < 1200px) {
    height: auto;
  }
`;

export const LogoContainer = styled.img`
  width: 30%;
  height: auto;

  @media only screen and (width < 1200px) {
    width: 40%;
    padding: 1rem 0;
  }
`;

// Display: none => Pas encore de réseaux sociaux à afficher
export const SocialMediaContainer = styled.div`
  width: 80%;
  height: 50%;
  background: purple;
  display: none;
`;

export const RightsReserved = styled.div`
  color: white;
  opacity: 0.7;
  font-size: 18px;
  text-align: center;
  justify-content: center;

  @media only screen and (width < 1200px) {
    padding: 1rem 0;
  }
`;

export const JoinUsText = styled.div`
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
`;

export const JoinUsSubtitle = styled.div`
  color: white;
  font-size: 24px;
  text-align: center;
  margin: 1rem 0;
`;

export const FormContainer = styled.div`
  width: 60%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (width < 1200px) {
    width: 90%;
  }
`;

export const SpacedGrid = styled(Grid)`
  margin: 1rem 0;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  background: white;
  color: black;
  border-radius: 5px;
`;

export const CenteredItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextFieldName = styled.div`
  font-size: 18px;
  color: black;
`;

export const SubmitButton = styled(motion.div)`
  width: 30%;
  height: 3rem;
  background: #062C3F;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  margin: 2rem 0;
`;
