import { Grid } from '@mui/material';
import DegradeBkg from 'assets/images/home/bkg_section_degrade.png';
import AccessBkg from 'assets/images/home/bkg_section3.jpg';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  display: flex;
  background: teal;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-image: url(${AccessBkg});
  padding-bottom: 5rem;

  @media only screen and (width < 1800px) {
    padding-bottom: 2rem;
    background-image: url(${DegradeBkg});
    background-repeat: repeat-y;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 100vh;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  width: 40%;
  padding-top: 5rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (width < 1800px) {
    top: 4rem;
  }

  @media only screen and (width < 1200px) {
    width: 70%;
  }
`;

export const Title = styled.div`
  font-size: 55px;
  color: white;
  font-weight: bold;
  line-height: 1.2;

  @media only screen and (width < 1200px) {
    font-size: 35px;
  }
`;

export const VIPTitle = styled.span`
  font-size: 55px;
  color: #FFC107;
  font-weight: bold;

  @media only screen and (width < 1200px) {
    font-size: 35px;
  }
`;

export const DescriptionContainer = styled.div`
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 3rem;

  @media only screen and (width < 1200px) {
    width: 80%;
  }
`;

export const Description = styled.div`
  font-size: 27px;
  color: white;

  @media only screen and (width < 1200px) {
    font-size: 20px;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: self-start;
  justify-content: center;

  @media only screen and (width < 1200px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const LeftCard = styled.div`
  height: 25rem;
  margin-left: 5rem;
  margin-top: 3rem;
  width: 100%;
  background: white;
  border-radius: 1.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 8px 8px 15px #363738;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    margin-left: 0;
    margin-top: 2rem;
    width: 180%;
  }
`;

export const RightCard = styled.div`
  height: 18rem;
  margin: 14rem 4rem 0 0;
  width: 100%;
  background: white;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    margin: 2rem 0 0;
    width: 180%;
  }
`;

export const RightCardText = styled.div`
  width: 60%;
  text-align: left;
  font-size: 23px;
  color: black;
  line-height: 1.5;

  @media only screen and (width < 1800px) {
    margin: 1rem;
  }

  @media only screen and (width < 1200px) {
    width: 90%;
    text-align: center;
    font-size: 18px;
  }
`;

export const LeftCardSide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftCardRightSide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  @media only screen and (width < 1200px) {
    text-align: center;
  }
`;

export const LeftCardImage = styled.img`
  width: 200px;
  height: 270px;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    width: 25%;
    margin: 1rem;
  }
`;

export const LeftCardText = styled.div`
  font-size: 23px;
  color: black;
  width: 90%;
  text-align: left;
  padding-right: 1rem;

  @media only screen and (width < 1800px) {
    margin: 1rem;
  }

  @media only screen and (width < 1200px) {
    text-align: center;
    font-size: 18px;
  }
`;

export const CardTextAccent = styled.span`
  font-size: 30px;
  color: teal;
  font-weight: bold;

  @media only screen and (width < 1200px) {
    font-size: 20px;
  }
`;

export const FullGrid = styled(Grid)`
  width: 100%;
  height: 100%;
`;
