import { Accordion } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: 5;
  align-items: center;
  justify-content: center;
  background: #062C3F;
`;

export const ImageBkg = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  background-size: cover;

  @media only screen and (width < 1800px) {
    display: none;
    height: 0;
    width: 0;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  position: relative;
  top: 0;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    flex-direction: column;
    width: 95%;
    padding: 0 1.5rem;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  text-align: left;

  @media only screen and (width < 1200px) {
    padding-top: 2rem;
  }
`;

export const FAQ = styled.div`
  font-size: 85px;
  line-height: 1.2;
  width: 90%;
  color: white;
  font-weight: bold;
  padding-bottom: 6rem;

  @media only screen and (width < 1800px) {
    width: 90%;
    font-size: 40px;
    padding-bottom: 3rem;
  }

  @media only screen and (width < 1200px) {
    padding-bottom: 1rem;
    font-size: 35px;
  }
`;

export const FAQDescription = styled.div`
  font-size: 26px;
  line-height: 1.3;
  color: white;
  width: 70%;

  @media only screen and (width < 1200px) {
    display: none;
  }
`;

export const Subtitle = styled.div`
  font-size: 26px;
  color: white;
  font-weight: bold;
  padding-bottom: 1rem;

  @media only screen and (width < 1200px) {
    font-size: 20px;
  }
`;

export const Description = styled.div`
  font-size: 26px;
  color: white;
  line-height: 1.3;

  @media only screen and (width < 1200px) {
    font-size: 18px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background: white;
  opacity: 0.6;
  margin: 2rem 0;

  @media only screen and (width < 1200px) {
    margin: 1rem 0;
  }
`;

export const BottomPadding = styled.div`
  padding-bottom: 5rem;
`;

export const GoldenSpan = styled.span`
  color: #FFC107;
  font-weight: bold;
  font-size: 26px;
`;

export const QuestionContainer = styled(motion.div)`
  width: 100%;
  height: auto;
  display: flex;
  text-align: left;
  flex-direction: column;
  cursor: pointer;
`;

export const StyledAccordion = styled(Accordion)`
  background: transparent;
  box-shadow: none;
`;
