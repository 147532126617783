import Home from 'modules/Home/Home';
import { Route, Switch } from 'wouter';

function Routes() {
  return (
    <Switch>
      <Route component={Home} />
    </Switch>
  );
}

export default Routes;
