import { Grid } from '@mui/material';
import AppImgEn from 'assets/images/home/App-en.png';
import AppImgFr from 'assets/images/home/App-fr.png';
import CurveImg from 'assets/images/home/bkg_section6_top.png';
import GreenCircle from 'assets/images/home/green_circle.png';
import LanguageContext from 'lib/providers/Language/LanguageContext';
import { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Centered } from '../shared';
import {
  CenteredContainer,
  Circle,
  ColumnContainer,
  ImageContainer,
  LeftTextContainer,
  PointContainer,
  SectionContainer,
  Subtitle,
  TextContainer,
  TitleContainer,
  TopCurve,
} from './styles';

function WorkSection() {
  const { language } = useContext(LanguageContext);

  const imgApp = useMemo(() => {
    switch (language) {
      case 'fr':
        return AppImgFr;
      default:
        return AppImgEn;
    }
  }, [language]);

  return (
    <SectionContainer>
      <TopCurve src={CurveImg} />
      <CenteredContainer>
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Centered>
              <TitleContainer>
                <FormattedMessage id="home_work_title" />
              </TitleContainer>
            </Centered>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} xs={12}>
            <Centered>
              <ColumnContainer>
                <Subtitle>
                  <FormattedMessage id="home_work_visitor" />
                </Subtitle>
                <PointContainer>
                  <Circle src={GreenCircle} />
                  <TextContainer>
                    <FormattedMessage id="home_work_visitor_1" />
                  </TextContainer>
                </PointContainer>
                <PointContainer>
                  <Circle src={GreenCircle} />
                  <TextContainer>
                    <FormattedMessage id="home_work_visitor_2" />
                  </TextContainer>
                </PointContainer>
                <PointContainer>
                  <Circle src={GreenCircle} />
                  <TextContainer>
                    <FormattedMessage id="home_work_visitor_3" />
                  </TextContainer>
                </PointContainer>
                <PointContainer>
                  <Circle src={GreenCircle} />
                  <TextContainer>
                    <FormattedMessage id="home_work_visitor_4" />
                  </TextContainer>
                </PointContainer>
              </ColumnContainer>
            </Centered>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Centered>
              <ColumnContainer>
                <LeftTextContainer>
                  <Subtitle>
                    <FormattedMessage id="home_work_organizer" />
                  </Subtitle>
                  <PointContainer>
                    <Circle src={GreenCircle} />
                    <TextContainer>
                      <FormattedMessage id="home_work_organizer_1" />
                    </TextContainer>
                  </PointContainer>
                  <PointContainer>
                    <Circle src={GreenCircle} />
                    <TextContainer>
                      <FormattedMessage id="home_work_organizer_2" />
                    </TextContainer>
                  </PointContainer>
                  <PointContainer>
                    <Circle src={GreenCircle} />
                    <TextContainer>
                      <FormattedMessage id="home_work_organizer_3" />
                    </TextContainer>
                  </PointContainer>
                  <PointContainer>
                    <Circle src={GreenCircle} />
                    <TextContainer>
                      <FormattedMessage id="home_work_organizer_4" />
                    </TextContainer>
                  </PointContainer>
                </LeftTextContainer>
              </ColumnContainer>
            </Centered>
          </Grid>
        </Grid>
      </CenteredContainer>
      <ImageContainer src={imgApp} />
    </SectionContainer>
  );
}

export default WorkSection;
