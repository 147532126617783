import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import {
  QuestionContainer,
  StyledAccordion,
  Subtitle,
} from './styles';

interface QuestionProps {
  question: string;
  answer: React.ReactNode;
}

function Question(props: QuestionProps) {
  const { question, answer } = props;

  return (
    <QuestionContainer>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Subtitle>
            <FormattedMessage id={question} />
          </Subtitle>
        </AccordionSummary>
        <AccordionDetails>
          {answer}
        </AccordionDetails>
      </StyledAccordion>
    </QuestionContainer>
  );
}

export default Question;
