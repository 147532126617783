import { Grid } from '@mui/material';
import standVIP from 'assets/images/home/illustration_vip_tickets.png';
import { FormattedMessage } from 'react-intl';

import {
  Benefits,
  BulletPoint,
  BulletPoints,
  BulletText,
  CenteredContainer,
  ImageContainer,
  LeftSideContainer,
  OrganizerLine,
  OrganizerQuestion,
  QuestionText,
  QuestionTextAccent,
  SecondSectionContainer,
  StandImage,
  Title,
  TitleAccent,
  TitleContainer,
} from './styles';

function OrganizerSection() {
  return (
    <SecondSectionContainer>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <CenteredContainer>
            <OrganizerLine />
            <OrganizerQuestion>
              <QuestionText>
                <FormattedMessage id="home_organizer_question_part_1" />
                <QuestionTextAccent>
                  <FormattedMessage id="home_organizer_question_organizer" />
                </QuestionTextAccent>
                <FormattedMessage id="home_organizer_question_part_2" />
              </QuestionText>
            </OrganizerQuestion>
            <LeftSideContainer>
              <TitleContainer>
                <Title>
                  <FormattedMessage id="home_organizer_title_part_1" />
                  <TitleAccent>
                    <FormattedMessage id="home_organizer_title_experience" />
                  </TitleAccent>
                  <FormattedMessage id="home_organizer_title_part_2" />
                </Title>
                <Benefits>
                  <FormattedMessage id="home_organizer_benefits" />
                </Benefits>
              </TitleContainer>
              <BulletPoints>
                <ul>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_organizer_benefit_1" />
                    </BulletText>
                  </BulletPoint>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_organizer_benefit_2" />
                    </BulletText>
                  </BulletPoint>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_organizer_benefit_3" />
                    </BulletText>
                  </BulletPoint>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_organizer_benefit_4" />
                    </BulletText>
                  </BulletPoint>
                  <BulletPoint>
                    <BulletText>
                      <FormattedMessage id="home_organizer_benefit_5" />
                    </BulletText>
                  </BulletPoint>
                </ul>
              </BulletPoints>
            </LeftSideContainer>
          </CenteredContainer>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CenteredContainer>
            <ImageContainer>
              <StandImage src={standVIP} alt="VIP Stand" />
            </ImageContainer>
          </CenteredContainer>
        </Grid>
      </Grid>
    </SecondSectionContainer>
  );
}

export default OrganizerSection;
