import styled from 'styled-components';

export const SectionContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9f4f6;

  @media only screen and (width < 1800px) {
    height: auto;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    width: 90%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media only screen and (width < 1200px) {
    margin-top: 2.5rem;
  }
`;

export const TitleBold = styled.div`
  font-size: 55px;
  color: teal;
  font-weight: bold;
  height: 65px;
  margin-bottom: 5px;

  @media only screen and (width < 1200px) {
    margin-bottom: 3rem;
    font-size: 35px;
  }
`;

export const Title = styled.div`
  font-size: 55px;
  color: teal;

  @media only screen and (width < 1200px) {
    font-size: 35px;
  }
`;

export const SidesContainer = styled.div`
  padding-top: 6rem;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media only screen and (width < 1200px) {
    flex-direction: column;
    padding-top: 4rem;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (width < 1200px) {
    padding: 0 1rem;
  }
`;

export const ImageContainer = styled.img`
  width: 16rem;
  height: 8rem;

  @media only screen and (width < 1200px) {
    margin-top: 2rem;
    width: 12rem;
    height: 6rem;
  }
`;

export const PaddingCenter = styled.div`
  padding: 0 8rem;

  @media only screen and (width < 1200px) {
    padding: 0;
  }
`;

export const Subtitle = styled.div`
  font-size: 33px;
  color: black;
  text-align: center;
  padding-top: 3rem;
  font-weight: bold;

  @media only screen and (width < 1200px) {
    padding-top: 1rem;
    font-size: 25px;
  }
`;

export const Description = styled.div`
  font-size: 27px;
  color: black;
  text-align: center;
  padding-top: 1rem;
  width: 100%;

  @media only screen and (width < 1200px) {
    font-size: 20px;
  }
`;
