import styled from 'styled-components';

export const ThirdSectionContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;

  @media only screen and (width < 1200px) {
    padding-bottom: 1rem;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    padding: 0 1.5rem;
  }
`;

export const CenteredImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  @media only screen and (width < 1800px) {
    height: auto;
  }
`;

export const ImageContainer = styled.img`
  position: relative;
  right: 0;
  width: 95%;
  height: 95vh;
  margin-left: 2rem;

  @media only screen and (width < 1800px) {
    height: auto;
  }
`;

export const VisitorLine = styled.div`
  position: absolute;
  top: 5rem;
  left: 0;
  width: 11rem;
  height: 3px;
  background: teal;

  @media only screen and (width < 1200px){
    width: 2rem;
    height: 1px;
  }
`;

export const VisitorQuestion = styled.div`
  position: absolute;
  top: 3rem;
  left: 13rem;
  width: 30rem;
  height: auto;

  @media only screen and (width < 1200px) {
    left: 1rem;
    width: 25rem;
    padding: 0 1.5rem;
  }
`;

export const QuestionText = styled.div`
  font-size: 23px;
  color: teal;
  text-transform: uppercase;

  @media only screen and (width < 1200px) {
    width: 85%;
    font-size: 16px;
  }
`;

export const QuestionTextAccent = styled.span`
  font-size: 23px;
  color: teal;
  font-weight: bold;
  text-transform: uppercase;

  @media only screen and (width < 1200px) {
    font-size: 16px;
  }
`;

export const LeftSideContainer = styled.div`
  width: 100%;
  padding-left: 13rem;
  padding-top: 17rem;

  @media only screen and (width < 1200px) {
    padding-top: 10rem;
    padding-left: 0;
  }
`;

export const TitleContainer = styled.div`
  width: 90%;
  height: auto;

  @media only screen and (width < 1200px) {
    padding-left: 1rem;
    padding-top: 0;
  }
`;

export const VisitorTitle = styled.div`
  font-size: 55px;
  color: black;
  font-weight: bold;
  line-height: 1.2;


  @media only screen and (width < 1200px) {
    font-size: 30px;
  }
`;

export const VisitorTitleAccent = styled.span`
  font-size: 55px;
  color: teal;
  font-weight: bold;
  line-height: 1.2;


  @media only screen and (width < 1200px) {
    font-size: 30px;
  }
`;

export const VisitorTitleImg = styled.img`
  width: 50px;
  height: 50px;
  position: relative;
  top: 5px;
  bottom: 0;
  margin-right: 5px;

  @media only screen and (width < 1200px) {
    width: 30px;
    height: 30px;
  }
`;

export const VisitorImgContainer = styled.span`
  position: relative;
`;

export const FasterTitleContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const QueueContainer = styled.div`
  padding-left: 3rem;
  padding-bottom: 1rem;
  width: 100%;

  @media only screen and (width < 1200px) {
    padding-left: 0;
  }
`;

export const QueuePoint = styled.li`
  color: grey;
  font-size: 28px;

  @media only screen and (width < 1200px) {
    font-size: 18px;
    margin-left: 1rem;
  }
`;

export const QueueText = styled.span`
  color: black;
  font-size: 24px;

  @media only screen and (width < 1200px) {
    font-size: 18px;
  }
`;

export const QueueVipText = styled.span`
  color: teal;
  font-size: 28px;
  font-weight: bold;

  @media only screen and (width < 1200px) {
    font-size: 18px;
  }
`;
