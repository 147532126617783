import { Grid } from '@mui/material';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;

  @media only screen and (width < 1800px) {
    height: auto;
  }

  @media only screen and (width < 1200px) {
    width: 85%;
  }
`;

export const StyledContainerGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  width: 60%;
  padding: 2rem 0;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media only screen and (width < 1800px) {
    width: 95%;
  }
`;

export const Title = styled.div`
  color: black;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;

  @media only screen and (width < 1200px) {
    font-size: 35px;
  }
`;

export const CaseContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;

  @media only screen and (width < 1200px) {
    padding: 1rem 0;
  }
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 5rem;

  @media only screen and (width < 1200px) {
    padding-bottom: 7rem;
  }
`;

export const CaseImage = styled.img`
  width: 30%;
  height: auto;
  margin-bottom: 1rem;

  @media only screen and (width < 1200px) {
    width: 50%;
  }
`;

export const CaseTitle = styled.div`
  color: black;
  text-transform: uppercase;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;

  @media only screen and (width < 1200px) {
    font-size: 16px;
  }
`;
