import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import {
  EventOrganizerText,
  LearnMoreButton,
  LearnMoreButtonHolder,
  LearnMoreContainer,
  OfficialLaunchText,
  OpeningDiv,
  PicRight,
  RightDiv,
  SaveTimeText,
} from './styles';

interface HomeIntroProps {
  handleScrollOrganizer: () => void
}

function HomeIntro(props: HomeIntroProps) {
  const { handleScrollOrganizer } = props;
  return (
    <OpeningDiv>
      <Grid container>
        <Grid item xs={12} lg={5.5}>
          <LearnMoreContainer>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <RightDiv>
                  <SaveTimeText>
                    <FormattedMessage id="home_intro_title" />
                  </SaveTimeText>
                </RightDiv>
              </Grid>
              <Grid item xs={12} lg={12}>
                <RightDiv>
                  <OfficialLaunchText>
                    <FormattedMessage id="home_intro_subtitle" />
                  </OfficialLaunchText>
                </RightDiv>
              </Grid>
              <Grid item xs={12} lg={12}>
                <RightDiv>
                  <LearnMoreButtonHolder>
                    <LearnMoreButton
                      onClick={handleScrollOrganizer}
                      whileTap={{ scale: 0.9, opacity: 0.9 }}
                    >
                      <FormattedMessage id="home_intro_button" />
                    </LearnMoreButton>
                  </LearnMoreButtonHolder>
                </RightDiv>
              </Grid>
              <Grid item xs={12} lg={12}>
                <RightDiv>
                  <EventOrganizerText
                    onClick={handleScrollOrganizer}
                    whileTap={{ scale: 0.9, opacity: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                  >
                    <FormattedMessage id="home_intro_organizer" />
                  </EventOrganizerText>
                </RightDiv>
              </Grid>
            </Grid>
          </LearnMoreContainer>
        </Grid>
        <Grid item xs={12} lg={6.5}>
          <PicRight />
        </Grid>
      </Grid>
    </OpeningDiv>
  );
}

export default HomeIntro;
